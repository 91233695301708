<template>
  <div class="wrapper flex align-start justify-between">
    <div class="flex-sub">
      <el-form :model="contractForm"
               label-width="180px"
               :rules="rules" ref="contractForm" class="add-form">

        <el-form-item label="姓名" prop="xingming">
          <el-input v-model="contractForm.xingming" placeholder="请填写姓名" disabled></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="xingbie" >
          <el-select v-model="contractForm.xingbie" disabled placeholder="请选择性别" class="w-all">
            <el-option
                v-for="item in sexList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="身份证/通行证" prop="shenfenzhenghao" >
          <el-input v-model="contractForm.shenfenzhenghao" disabled placeholder="请填写身份证/通行证"></el-input>
        </el-form-item>
        <el-form-item label="合同编号" prop="contractNo">
          <el-input v-model="contractForm.contractNo" placeholder="请填写合同编号"></el-input>
        </el-form-item>
        <el-form-item label="劳动合同签订日期" prop="contractSignDate">
          <el-date-picker align="center"
                          class="w-all"
                          v-model="contractForm.contractSignDate"
                          type="date"
                          placeholder="请选择劳动合同签订日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="合同类型" prop="contractType">
          <el-select v-model="contractForm.contractType" placeholder="请选择合同类型" class="w-all"
                     @change="setContractType">
            <el-option
                v-for="item in contractTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>


        <el-form-item label="固定期限合同时间" prop="contractDate" v-if="contractForm.contractType === 1">
          <el-date-picker class="w-all"
                          v-model="contractForm.contractDate"
                          type="daterange"
                          range-separator="至"
                          start-placeholder="固定期限合同开始日期"
                          end-placeholder="固定期限合同结束日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="无固定期限开始时间" prop="nonFixedTermStartDate" v-if="contractForm.contractType === 2">
          <el-date-picker align="center"
                          class="w-all"
                          v-model="contractForm.nonFixedTermStartDate"
                          type="date"
                          placeholder="请选择无固定期限开始时间">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="工作任务期限合同时间" prop="workDate" v-if="contractForm.contractType === 3">
          <el-date-picker class="w-all"
                          v-model="contractForm.workDate"
                          type="daterange"
                          range-separator="至"
                          start-placeholder="工作任务期限开始日期"
                          end-placeholder="工作任务期限结束日期">
          </el-date-picker>
        </el-form-item>


        <el-form-item label="试用期时间" prop="probationDate">
          <el-date-picker class="w-all"
                          v-model="contractForm.probationDate"
                          type="daterange"
                          range-separator="至"
                          start-placeholder="试用期开始日期"
                          end-placeholder="试用期结束日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="入职日期" prop="ruzhiriqi">
          <el-date-picker align="center"
                          class="w-all"
                          v-model="contractForm.ruzhiriqi"
                          type="date"
                          placeholder="请选择入职日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="岗位名称" prop="gangweimingcheng">
          <el-input v-model="contractForm.gangweimingcheng" placeholder="请填写岗位名称"></el-input>
        </el-form-item>
        <el-form-item label="工作地点" prop="gongzuodidian">
          <el-input v-model="contractForm.gongzuodidian" placeholder="请填写工作地点"></el-input>
        </el-form-item>

        <el-form-item label="工时制方式" prop="workType">
          <el-select v-model="contractForm.workType" placeholder="请选择工时制方式" class="w-all">
            <el-option
                v-for="item in workTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>


        <el-form-item label="月薪" prop="jibenyuexin">
          <el-input v-model="contractForm.jibenyuexin" placeholder="请填写月薪" type="number">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="试用期工资比例" prop="shiyongqigongzi">
          <el-input v-model="contractForm.shiyongqigongzi" placeholder="请填写试用期工资比例" type="number">
            <template slot="append">%</template>
          </el-input>
        </el-form-item>

        <el-form-item label="工资形式" prop="paymentType">
          <el-select v-model="contractForm.paymentType" placeholder="请选择工资形式" class="w-all">
            <el-option
                v-for="item in paymentTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="合同附件份数" prop="appendixNubmer">
          <el-input v-model="contractForm.appendixNubmer" placeholder="请填写合同附件份数" type="number">
            <template slot="append">份</template>
          </el-input>
        </el-form-item>
        <el-form-item label="合同签订日期" prop="cASignDate">
          <el-date-picker align="center"
                          class="w-all"
                          v-model="contractForm.cASignDate"
                          type="date"
                          placeholder="请选择合同签订日期">
          </el-date-picker>
        </el-form-item>

        <!-- <el-form-item label="签署年限" prop="qianshunianxian">-->
        <!--          <el-input v-model="contractForm.qianshunianxian" placeholder="请填写签署年限" type="number">-->
        <!--            <template slot="append">年</template>-->
        <!--          </el-input>-->
        <!--        </el-form-item>-->


        <el-form-item label-width="0" class="flex justify-around">
          <el-button class="submit-btn" type="primary" @click="submitForm('contractForm')">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="flex-sub flex align-center justify-between flex-direction preview padding-lr">
      <div class="preview-info">

        <div class="file-preview-tab flex align-center justify-around" v-if="sys_offer_hetong_file">
          <div class="tab-item" :class="{'active':tabItem === 1}" @click="tabItem = 1">劳动合同</div>
          <div class="tab-item" :class="{'active':tabItem === 2}" @click="tabItem = 2">保密协议</div>
        </div>

        <div class="preview-tips" v-if="!sys_offer_hetong_file">保存后可预览</div>

        <iframe v-show="sys_offer_hetong_file && tabItem === 1" ref="iframe1" frameborder="0"
                style="width: 100%;height: calc(100vh - 415px);"></iframe>

        <iframe v-show="sys_offer_baomi_file && tabItem === 2" ref="iframe2" frameborder="0"
                style="width: 100%;height: calc(100vh - 415px);"></iframe>
      </div>

      <div class="flex align-center justify-around margin-top">
        <el-button class="submit-btn margin-right" type="primary" @click="downOffer">下载劳动合同/保密协议
        </el-button>
        <el-upload
            ref="upBtn"
            class="upload-demo margin-right"
            :action="actions"
            :data="{token}"
            :on-success="handleSuccess"
            :limit="1"
            :show-file-list="false"
        >
          <el-button class="submit-btn" type="warning">上传劳动合同反馈</el-button>
        </el-upload>
        <el-upload
            ref="upBtn2"
            class="upload-demo"
            :action="actions"
            :data="{token}"
            :on-success="handleSuccess2"
            :limit="1"
            :show-file-list="false"
        >
          <el-button class="submit-btn" type="warning">上传保密协议反馈</el-button>

        </el-upload>
      </div>
      <div class="flex align-center justify-center margin-top" v-if="upload_hetong_file || upload_baomi_file">
        <div>反馈附件：</div>
        <el-link v-if="upload_hetong_file" :href="upload_hetong_file" target="_blank" type="primary"
                 class="margin-right">
          下载劳动合同反馈附件
        </el-link>
        <el-link v-if="upload_baomi_file" :href="upload_baomi_file" target="_blank" type="primary">
          下载保密协议反馈附件
        </el-link>
      </div>


    </div>

  </div>
</template>

<script>

import rules from "@/utils/rules";
import {downLoadOfferWord, protocolShow, replyProtocolFile, staffFullTimeProtocol} from "@/api/entry";
import {QAHOST, upFile} from "@/config";
import {getOfferHtml} from "@/api/common";
import {getPageName, zhDate} from "@/utils";
import {contractTypeList, paymentTypeList, sexList, workTypeList} from "@/utils/const";

export default {
  name: "OrgList",
  data() {
    return {
      id: '',
      sexList: sexList,
      contractTypeList: contractTypeList,
      workTypeList: workTypeList,
      paymentTypeList: paymentTypeList,
      contractForm: {
        contractNo: '',
        xingming: '',
        xingbie: '',
        shenfenzhenghao: '',
        contractSignDate: '',
        contractType: '',

        contractDate: [],
        nonFixedTermStartDate: '',
        workDate: [],

        probationDate: [],
        ruzhiriqi: '',
        gangweimingcheng: '',
        gongzuodidian: '',
        workType: '',
        jibenyuexin: '',
        shiyongqigongzi: '',
        paymentType: '',
        appendixNubmer: '',
        cASignDate: '',
        qianshunianxian: '',


      },
      rules: {
        contractNo: rules.mustInput,
        xingming: rules.mustInput,
        xingbie: rules.mustSelect,
        shenfenzhenghao: rules.mustInput,
        contractSignDate: rules.mustSelect,
        contractType: rules.mustSelect,
        contractDate: rules.mustSelect,
        nonFixedTermStartDate: rules.mustSelect,
        workDate: rules.mustSelect,
        probationDate: rules.mustSelect,
        ruzhiriqi: rules.mustDate,
        gangweimingcheng: rules.mustInput,
        gongzuodidian: rules.mustInput,
        workType: rules.mustInput,
        jibenyuexin: rules.mustInput,
        shiyongqigongzi: rules.mustInput,
        paymentType: rules.mustSelect,
        appendixNubmer: rules.mustInput,
        cASignDate: rules.mustSelect,
        qianshunianxian: rules.mustInput,


      },
      isSubmit: true,
      sys_offer_hetong_file: '',
      sys_offer_baomi_file: '',
      upload_hetong_file: '',
      upload_baomi_file: '',
      iframe1: '',
      iframe2: '',
      tabItem: 1,
    }
  },
  computed: {
    token() {
      return this.$store.state.token
    },
    actions() {
      return upFile
    }
  },
  created() {
    this.$emit("setIndex", '/admin/expertList', "全职员工劳动合同", true);
    this.id = Number(this.$route.params.id)
    this.protocolShow()
  },
  components: {},
  methods: {
    async protocolShow() {

      const res = await protocolShow(this.id, 1)
      this.sys_offer_hetong_file = res.data.sys_offer_hetong_file
      this.sys_offer_baomi_file = res.data.sys_offer_baomi_file
      this.upload_baomi_file = res.data.upload_baomi_file ? QAHOST + '/' + res.data.upload_baomi_file : ''
      this.upload_hetong_file = res.data.upload_hetong_file ? QAHOST + '/' + res.data.upload_hetong_file : ''
      this.contractForm = {
        contractNo: res.data.contractNo,
        xingming: res.data.xingming,
        xingbie: Number(res.data.xingbie),
        shenfenzhenghao: res.data.shenfenzhenghao,
        contractSignDate: res.data.contractSignDate ? new Date(res.data.contractSignDate) : '',
        contractType: res.data.contractType?Number(res.data.contractType):'',
        contractDate: res.data.contractStartDate ? [new Date(res.data.contractStartDate), new Date(res.data.contractEndDate)] : [],
        nonFixedTermStartDate: res.data.nonFixedTermStartDate ? new Date(res.data.nonFixedTermStartDate) : '',
        workDate: res.data.workStartDate ? [new Date(res.data.workStartDate), new Date(res.data.workEndDate)] : [],
        probationDate: res.data.probationStartDate ? [new Date(res.data.probationStartDate), new Date(res.data.probationEndDate)] : [],
        ruzhiriqi: res.data.ruzhiriqi ? new Date(res.data.ruzhiriqi) : '',
        gangweimingcheng: res.data.gangweimingcheng,
        gongzuodidian: res.data.gongzuodidian,
        workType: res.data.workType?Number(res.data.workType):'',
        jibenyuexin: res.data.jibenyuexin,
        shiyongqigongzi: res.data.shiyongqigongzi,
        paymentType: res.data.paymentType?Number(res.data.paymentType):'',
        appendixNubmer: res.data.appendixNubmer,
        cASignDate: res.data.cASignDate ? new Date(res.data.cASignDate) : '',
        qianshunianxian: res.data.qianshunianxian,
      }

      if (res.data.sys_offer_hetong_file) {

        const iframe1 = await getOfferHtml(getPageName(res.data.sys_offer_hetong_file))
        const iframe2 = await getOfferHtml(getPageName(res.data.sys_offer_baomi_file))
        this.iframe1 = QAHOST + '/' + iframe1.data + '?v=' + new Date().getTime()
        this.iframe2 = QAHOST + '/' + iframe2.data + '?v=' + new Date().getTime()

        this.$refs.iframe1.contentWindow.location.replace(this.iframe1)
        this.$refs.iframe2.contentWindow.location.replace(this.iframe2)


      }


    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sureAdd()
        }
      });
    },
    async sureAdd() {
      let _state = await staffFullTimeProtocol(
          this.id,
          this.contractForm.contractNo,
          this.contractForm.xingming,
          this.contractForm.xingbie,
          this.contractForm.shenfenzhenghao,
          zhDate(new Date(this.contractForm.contractSignDate)),
          this.contractForm.contractType,
          this.contractForm.contractType == 1 ? zhDate(new Date(this.contractForm.contractDate[0])) : '',
          this.contractForm.contractType == 1 ? zhDate(new Date(this.contractForm.contractDate[1])) : '',
          this.contractForm.nonFixedTermStartDate?zhDate(new Date(this.contractForm.nonFixedTermStartDate)):'',
          this.contractForm.contractType == 3 ? zhDate(new Date(this.contractForm.workDate[0])) : '',
          this.contractForm.contractType == 3 ? zhDate(new Date(this.contractForm.workDate[1])) : '',
          zhDate(new Date(this.contractForm.probationDate[0])),
          zhDate(new Date(this.contractForm.probationDate[1])),
          zhDate(new Date(this.contractForm.ruzhiriqi)),
          this.contractForm.gangweimingcheng,
          this.contractForm.gongzuodidian,
          this.contractForm.workType,
          this.contractForm.jibenyuexin,
          this.contractForm.shiyongqigongzi,
          this.contractForm.paymentType,
          this.contractForm.appendixNubmer,
          zhDate(new Date(this.contractForm.cASignDate)),
          this.contractForm.qianshunianxian,
      )

      if (_state.status === 200) {
        this.$message.success(_state.info)
        await this.protocolShow()
      } else {
        this.$message.error(_state.info)
      }
    },
    //  下载附件
    async downOffer() {
      if (!this.iframe1) return this.$message.error('请编辑保存后生成')
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let _data1 = await downLoadOfferWord(this.id, this.sys_offer_hetong_file)
      let _data2 = await downLoadOfferWord(this.id, this.sys_offer_baomi_file)
      this.downFile(_data1, '劳务合同')
      this.downFile(_data2, '保密协议')
      loading.close();

    },

    downFile(_data, str) {
      let blob = _data.data
      var downloadElement = document.createElement('a');
      var href = window.URL.createObjectURL(blob); //创建下载的链接
      downloadElement.style.display = 'none';
      downloadElement.href = href;
      downloadElement.setAttribute('download', this.contractForm.xingming + str + '.docx');
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href); //释放掉blob对象
    },


    handleSuccess(file) {
      if (file.status === 200) {
        this.upFile(file.data, 1)
      } else {
        this.$message.error(file.msg)
      }
      this.$refs['upBtn'].clearFiles()
    },
    handleSuccess2(file) {
      if (file.status === 200) {
        this.upFile(file.data, 2)
      } else {
        this.$message.error(file.msg)
      }
      this.$refs['upBtn2'].clearFiles()
    },
    async upFile(url, type) {

      await replyProtocolFile(this.id, 1, type === 1 ? url : '', type === 2 ? url : '')
      this.$message.success('上传成功')
      await this.protocolShow()
    },
    setContractType() {
      this.contractForm.contractDate = []
      this.contractForm.nonFixedTermStartDate = ''
      this.contractForm.workDate = []
    }
  },
  filters: {},
  watch: {},
}
</script>

<style scoped lang="scss">
.preview {
  height: 100%;

  .preview-title {
    text-align: center;
    line-height: 60px;
    font-size: 22px;
  }

  .preview-info {
    width: 100%;
    height: calc(100vh - 330px);
    overflow-y: auto;
    border: 1px solid #DCDFE6;
    border-radius: 10px;
  }

  .file-preview-tab {
    padding: 20px 0;

    .tab-item {
      width: 200px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      border-radius: 5px;
      border: 1px solid #368ad1;
      color: #368ad1;
      cursor: pointer;

      &.active {
        background: #368ad1;
        color: #fff;
      }
    }
  }

}
</style>

